<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card>
                <b-row>
                    <b-col md="6" lg="4" class="mb-3">
                        <h4 class="mb-2">Logo <tooltip-with-image img="/img/banner/logo.png"/></h4>
                        <ImageSelector ref="ImageSelectorLogo" v-model="config.logo_image"/>
                    </b-col>
                    <b-col md="6" lg="4" class="mb-3">
                        <h4 class="mb-2">Background <tooltip-with-image img="/img/banner/background.png"/></h4>
                        <BackgroundSelector ref="BackgroundSelectorBackground" v-model="config.background" :use-opacity="true"/>
                    </b-col>
                    <b-col md="6" lg="4" class="mb-3">
                        <div>
                            <h4 class="mb-2">Primary text <tooltip-with-image img="/img/banner/primary.png"/></h4>
                            <b-form-group>
                                <ColorWithOpacitySelector v-model="config.primary_text.color"/>
                            </b-form-group>
                            <b-form-group>
                                <label>Template:<fa id="template_first" class="ml-1" icon="question-circle" />
                                    <b-tooltip target="template_first" placement="topright" triggers="hover focus" custom-class="expanded-tooltip">
                                        <b>The following options will be replaced when displayed:</b>
                                        <ul>
                                            <li v-pre>{{.name}} - Guest name - "John Smith" </li>
                                            <li v-pre>{{.room_name}} - Name of room - "A-101" </li>
                                            <li v-pre>{{.room_location}} - Location of room - "West"</li>
                                        </ul>
                                    </b-tooltip></label>
                                <b-form-input
                                    type="text"
                                    placeholder="Template"
                                    v-model="config.primary_text.template"
                                />
                            </b-form-group>
                        </div>

                        <div class="mt-5">
                            <h4 class="mb-2">Secondary text <tooltip-with-image img="/img/banner/secondary.png"/></h4>
                            <b-form-group>
                                <ColorWithOpacitySelector v-model="config.secondary_text.color"/>
                            </b-form-group>
                            <b-form-group>
                                <label>Template:<fa id="template_second" class="ml-1" icon="question-circle" />
                                    <b-tooltip target="template_second" placement="topright" triggers="hover focus" custom-class="expanded-tooltip">
                                        <b>The following options will be replaced when displayed:</b>
                                        <ul>
                                            <li v-pre>{{.name}} - Guest name - "John Smith" </li>
                                            <li v-pre>{{.room_name}} - Name of room - "A-101" </li>
                                            <li v-pre>{{.room_location}} - Location of room - "West"</li>
                                        </ul>
                                    </b-tooltip></label>
                                <b-form-textarea
                                    type="text"
                                    placeholder="Template"
                                    v-model="config.secondary_text.template"
                                />
                            </b-form-group>
                        </div>
                        <div class="mt-5">
                            <h4 class="mb-2">Time <tooltip-with-image img="/img/banner/time.png"/></h4>
                            <b-form-group>
                                <ColorWithOpacitySelector v-model="config.time.color"/>
                                <label class="mt-1">Format:</label>
                                <v-select
                                    v-model="config.time.template"
                                    :reduce="item => item.format"
                                    label="name"
                                    :clearable="false"
                                    :options="timeFormats"
                                />
                            </b-form-group>
                        </div>
                        <div class="mt-5">
                            <h4 class="mb-2">Date <tooltip-with-image img="/img/banner/date.png"/></h4>
                            <b-form-group>
                                <ColorWithOpacitySelector v-model="config.date.color"/>
                                <label class="mt-1">Format:</label>
                                <v-select
                                    v-model="config.date.template"
                                    :reduce="item => item.format"
                                    label="name"
                                    :clearable="false"
                                    :options="dateFormats"
                                />
                            </b-form-group>
                        </div>
                    </b-col>
                </b-row>

                <hr/>

                <VariableTranslations :fields="[{name: 'Primary text', field: 'primary_text', type: 0},{name: 'Secondary text', field: 'secondary_text', type: 1}]" v-model="config.translations"/>

                <hr/>

                <DesignConfigSaveComponent v-model="saveName" v-bind:name-exists="this.nameExists" v-on:addConfig="(arg) => addConfig(arg)" v-on:replaceConfig="(arg) => replaceConfig(arg)"/>
            </b-card>
        </b-overlay>

        <ConfigsTable ref="configsTable" config-type="banner" v-on:dataLoaded="dataLoadedEvent" v-on:loadingStarted="loadingStartedEvent" v-on:loadConfig="loadConfig"/>
    </div>
</template>
<script>

    import { BCard, BOverlay, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BTooltip } from 'bootstrap-vue'
    import ConfigsTable from '@/views/components/config/ConfigsTable'
    import BackgroundSelector from '@/views/components/BackgroundSelector'
    import vSelect from 'vue-select'
    import ColorWithOpacitySelector from '@/views/components/ColorWithOpacitySelector'
    import VariableTranslations from '@/views/Translation/VariableTranslations'
    import ImageSelector from '@/views/components/ImageSelector'
    import DesignConfigSaveComponent from '@/views/components/DesignConfigSaveComponent'
    import TooltipWithImage from '@/views/components/TooltipWithImage'

    export default {
        components: {
            TooltipWithImage,
            DesignConfigSaveComponent,
            ImageSelector,
            VariableTranslations,
            ColorWithOpacitySelector,
            ConfigsTable,
            BOverlay,
            BCard,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BFormTextarea,
            BackgroundSelector,
            BTooltip,
            vSelect
        },
        data() {
            return {

                config: {
                    logo_image: '',
                    primary_text: {
                        color: {},
                        template: ''
                    },
                    secondary_text: {
                        color: {},
                        template: ''
                    },
                    time: {
                        color: {},
                        template: 'HH:mm:ss'
                    },
                    date: {
                        color: {},
                        template: 'yyyy/MM/dd'
                    },
                    background: {},
                    translations: []
                },

                dateFormats: [
                    {
                        format: 'yyyy/MM/dd',
                        name: '2021/01/20'
                    },
                    {
                        format: 'yy/MM/dd',
                        name: '21/01/20'
                    },
                    {
                        format: 'dd/MM/yyyy',
                        name: '20/01/2021'
                    },
                    {
                        format: 'dd/MM/yy',
                        name: '20/01/21'
                    },
                    {
                        format: 'MM/dd/yyyy',
                        name: '01/20/2021'
                    },
                    {
                        format: 'MM/dd/yy',
                        name: '01/20/21'
                    }
                ],

                timeFormats: [
                    {
                        format: 'HH:mm:ss',
                        name: '21:18:32'
                    },
                    {
                        format: 'hh:mm:ss',
                        name: '09:18:32 PM'
                    },
                    {
                        format: 'HH:mm',
                        name: '21:18'
                    },
                    {
                        format: 'hh:mm',
                        name: '09:18 PM'
                    }
                ],

                saveName: '',
                dataLoaded: false

            }
        },
        methods: {
            dataLoadedEvent() {
                this.dataLoaded = true
            },
            loadingStartedEvent() {
                this.dataLoaded = false
            },
            loadConfig(config) {
                this.config = config.config
                this.saveName = config.name
            },
            addConfig(addStatus) {
                if (!this.$refs.ImageSelectorLogo.valid()) {
                    this.$printError('Configuration is not valid. Check if all settings are correctly set')
                } else if (!this.$refs.BackgroundSelectorBackground.valid()) {
                    this.$printError('Configuration is not valid. Check if all settings are correctly set')
                } else {
                    const thisIns = this
                    const loadPromise = this.$http.post('/api/management/v1/config/design/banner', {
                        status: addStatus,
                        name: this.saveName,
                        config: this.config
                    })
                    loadPromise.then(function() {
                        thisIns.$printSuccess('Config saved')
                    }).catch(function(error) {
                        thisIns.$printError(error.response.data)
                    }).finally(function() {
                        thisIns.$refs.configsTable.loadData()
                    })
                }
            },
            replaceConfig(addStatus) {
                if (!this.$refs.ImageSelectorLogo.valid()) {
                    this.$printError('Configuration is not valid. Check if all settings are correctly set')
                } else if (!this.$refs.BackgroundSelectorBackground.valid()) {
                    this.$printError('Configuration is not valid. Check if all settings are correctly set')
                } else {
                    const thisIns = this
                    const loadPromise = this.$http.put('/api/management/v1/config/design/banner', {
                        status: addStatus,
                        name: this.saveName,
                        config: this.config
                    })
                    loadPromise.then(function() {
                        thisIns.$printSuccess('Config replaced')
                    }).catch(function(error) {
                        thisIns.$printError(error.response.data)
                    }).finally(function() {
                        thisIns.$refs.configsTable.loadData()
                    })
                }
            }
        },
        computed: {
            nameExists() {
                const localName = this.saveName
                if (!this.$refs.configsTable) return false
                return this.$refs.configsTable.getConfigs().some((item) => item.name === localName)
            }
        }
    }

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
